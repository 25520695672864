import { combineReducers } from 'redux';
import { urls, urlsWrapper } from 'config';
import { serialize, getContentDisposition, getFileUrl } from 'utils';
import { createAction } from 'redux-actions';
import download from 'downloadjs';
import { createCommonItemConstants, createCommonItemReducer } from './utils';
import { commonPost, commonGet } from './api';

const constants = {
  SET_LOADING: 'REPORTS_SET_LOADING',
  RESET_LOADING: 'REPORTS_RESET_LOADING',
  SET_ERROR: 'REPORTS_SET_ERROR',
  RESET_ERROR: 'REPORTS_RESET_ERROR',
};
const reportsProcessCheckingConstant = createCommonItemConstants(
  'REPORTS_CHECKING_PROCESS'
);

export const reportTypes = {
  SCOUTS_HISTORY: 'scoutsHistory',
  ADVISORS_BREED: 'advisorsBreed',
  ADVISORS_PESTICIDE: 'advisorsPesticide',
  ADVISORS_FILL_DATA: 'advisorsFillData',
  ADVISORS_LWBD: 'advisorsLWDB',
  ADVISORS_USER_SCOUTS: 'advisorUserScouts',
  ADVISORS_VISITS: 'advisorVisits',
  ADVISORS_YIELD: 'advisorYield',
  ADVISORS_YIELD_ALL: 'advisorYieldAll',
};

const isReportLoading = (
  state = {
    [reportTypes.SCOUTS_HISTORY]: false,
    [reportTypes.ADVISORS_BREED]: false,
    [reportTypes.ADVISORS_PESTICIDE]: false,
    [reportTypes.ADVISORS_FILL_DATA]: false,
    [reportTypes.ADVISORS_LWBD]: false,
    [reportTypes.ADVISORS_USER_SCOUTS]: false,
    [reportTypes.ADVISORS_VISITS]: false,
    [reportTypes.ADVISORS_YIELD]: false,
    [reportTypes.ADVISORS_YIELD_ALL]: false,
  },
  action
) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return { ...state, [action.payload.type]: true };

    case constants.RESET_LOADING:
      return { ...state, [action.payload.type]: false };

    default:
      return state;
  }
};

const reportError = (
  state = {
    [reportTypes.SCOUTS_HISTORY]: '',
    [reportTypes.ADVISORS_BREED]: '',
    [reportTypes.ADVISORS_PESTICIDE]: '',
    [reportTypes.ADVISORS_FILL_DATA]: '',
    [reportTypes.ADVISORS_LWBD]: '',
    [reportTypes.ADVISORS_USER_SCOUTS]: '',
    [reportTypes.ADVISORS_VISITS]: '',
    [reportTypes.ADVISORS_YIELD]: '',
    [reportTypes.ADVISORS_YIELD_ALL]: '',
  },
  action
) => {
  switch (action.type) {
    case constants.SET_ERROR:
      return { ...state, [action.payload.type]: action.payload.error };

    case constants.RESET_ERROR:
      return { ...state, [action.payload.type]: '' };

    default:
      return state;
  }
};

// reducer
export default combineReducers({
  isReportLoading,
  reportError,
  reportCheckingProcess: createCommonItemReducer(
    reportsProcessCheckingConstant
  ),
});

export const setReportLoading = createAction(constants.SET_LOADING);
export const resetReportLoading = createAction(constants.RESET_LOADING);
export const setReportError = createAction(constants.SET_ERROR);
export const resetReportError = createAction(constants.RESET_ERROR);
export const setProcess = createAction(reportsProcessCheckingConstant.SET_ITEM);

// actions
const exportScoutsHistory = async (params, url, notNeedAuthHeader) => {
  const mimeType =
    params.format === 'xlsx' ||
    params.format === 'xlsxsum' ||
    params.format === 'xlsxstat' ||
    params.format === 'xlsxtrials' ||
    params.format === 'xlsxanalytics'
      ? 'application/vnd.ms-excel'
      : 'application/pdf';
  const extention =
    params.format === 'xlsxsum' || params.format === 'xlsxanalytics'
      ? 'xlsx'
      : params.format;
  const response = await (!notNeedAuthHeader ? commonPost : commonGet)({
    url,
    responseType: 'blob',
    ...(notNeedAuthHeader
      ? {
          transformRequest: (data, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.Authorization;
            return data;
          },
        }
      : {}),
  });
  const fileName =
    getContentDisposition(response.headers) || `scouts.${extention}`;
  download(response.data, fileName, mimeType);
  return response;
};

const intervalStatusCheck = async (
  url,
  params,
  dispatch,
  interval,
  type,
  onSuccess
) => {
  try {
    const { headers: headersStatus, status, data: postData } = await commonPost(
      {
        url,
        errorMessage: ' ',
      }
    );
    if (status !== 204) {
      clearTimeout(interval[type]);
      const resultUrl = getFileUrl(postData.location || headersStatus.location);
      if (postData.location) {
        const { data: downloadData } = await commonPost({
          url: resultUrl,
        });
        const resultDownloadUrl = getFileUrl(downloadData.location);
        await exportScoutsHistory(
          params,
          resultDownloadUrl,
          !!postData.location
        );
      } else {
        await exportScoutsHistory(params, resultUrl, !!postData.location);
      }
      onSuccess?.();
      dispatch(resetReportLoading({ type }));
      return false;
    }
    if (headersStatus['x-process-progress']) {
      dispatch(setProcess(headersStatus['x-process-progress']));
    }
    return true;
  } catch (error) {
    dispatch(setReportError({ type, error: error.errors._error || '' }));
    clearTimeout(interval[type]);
    dispatch(resetReportLoading({ type }));
    dispatch(setProcess(null));
    return false;
  }
};

const interval = {
  [reportTypes.SCOUTS_HISTORY]: undefined,
  [reportTypes.ADVISORS_BREED]: undefined,
  [reportTypes.ADVISORS_PESTICIDE]: undefined,
  [reportTypes.ADVISORS_FILL_DATA]: undefined,
  [reportTypes.ADVISORS_LWBD]: undefined,
  [reportTypes.ADVISORS_USER_SCOUTS]: undefined,
  [reportTypes.ADVISORS_VISITS]: undefined,
  [reportTypes.ADVISORS_YIELD]: undefined,
  [reportTypes.ADVISORS_YIELD_ALL]: undefined,
};

const checkLogic = async (url, params, dispatch, type, onSuccess) => {
  const result = await intervalStatusCheck(
    url,
    params,
    dispatch,
    interval,
    type,
    onSuccess
  );
  if (result) {
    interval[type] = setTimeout(() => {
      checkLogic(url, params, dispatch, type, onSuccess);
    }, 5000);
  }
};

const createReport = ({ params, reportUrl, type, data, onSuccess }) => async (
  dispatch
) => {
  dispatch(setReportLoading({ type }));
  dispatch(resetReportError({ type }));
  dispatch(setProcess(null));
  try {
    const response = await commonPost({
      url: `${reportUrl}?${serialize(params)}`,
      errorMessage: ' ',
      data,
    });
    const url = getFileUrl(
      response.data?.location || response.headers.location
    );
    checkLogic(url, params, dispatch, type, onSuccess);
  } catch (e) {
    dispatch(setReportError({ type, error: e.errors._error || '' }));
    clearTimeout(interval[type]);
    dispatch(resetReportLoading({ type }));
  }
};

export const createScoutsHistoryReport = (params, data, onSuccess) =>
  createReport({
    params,
    data,
    reportUrl: urlsWrapper('scoutsReport'),
    type: reportTypes.SCOUTS_HISTORY,
    onSuccess,
  });

export const createAdvisorsBreedReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_BREED,
  });

export const createAdvisorsYieldReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_YIELD,
  });

export const createAdvisorsYieldAllReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_YIELD_ALL,
  });

export const createAdvisorsPesticideReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_PESTICIDE,
  });

export const createAdvisorsFillDataReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_FILL_DATA,
  });

export const createAdvisorsLWBDReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_LWBD,
  });

export const createAdvisorsUserScoutsReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_USER_SCOUTS,
  });

export const createAdvisorsVisitsReport = (params) =>
  createReport({
    params,
    reportUrl: urls().advisorsReport,
    type: reportTypes.ADVISORS_VISITS,
  });
