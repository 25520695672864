import React from 'react';
import ReactDOM from 'react-dom';
import Metrics from 'utils/metrics';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Chatra from '@chatra/chatra';
import { addPageHeadSettings } from 'utils/addPageHeadSettings';
import { env_externalservices, env_commit, NODE_ENV } from 'env';
import { configureStore } from 'store';
import Routes from 'routes';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/index.scss';
import 'antd/dist/antd.css';
import 'moment/locale/ru';
import './polyfills';
import { BrowserSupport } from 'components/common/BrowserSupport';

if (NODE_ENV === 'production' && env_externalservices === 'true') {
  Sentry.init({
    dsn:
      'https://4bc46cf771194eff946ea4b39ecc3fde@o586667.ingest.sentry.io/5738322',
    release: env_commit || 'localhost',
    ignoreErrors: [
      'Network request failed',
      'Network Error',
      'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
      'NetworkError when attempting to fetch resource.',
      'Failed to fetch',
      'Request aborted',
      'Unauthorized access',
      'Submit Validation Failed',
      'Request failed with status code',
      'Request failed',
      'timeout of 0ms exceeded',
    ],
  });
}

if (env_externalservices === 'true') {
  const config = {
    ID: 'hKorsQhQXcbZgENgY',
    setup: {
      colors: {
        buttonText: '#f0f0f0',
        buttonBg: '#f5cb5c',
      },
      buttonPosition: 'bl',
    },
  };

  Chatra('init', config);
}

if (env_externalservices === 'true' && NODE_ENV !== 'test') {
  Metrics.init({
    mixpanelToken: '359a94312382e022102ecbb4e5c52867',
    release: env_commit || 'localhost',
    domain: window.location.origin,
    debug: NODE_ENV !== 'production',
    env: NODE_ENV,
  });
  const userId = localStorage.getItem('USER_ID');
  if (userId) {
    Metrics.identify(userId);
  }
}

addPageHeadSettings();

const store = configureStore();

const renderApp = (Component) => (
  <BrowserSupport>
    <Provider store={store}>
      <Component />
    </Provider>
  </BrowserSupport>
);

const rootElement = document.getElementById('root');

ReactDOM.render(renderApp(Routes), rootElement);

if (module.hot) {
  // Routes keeps the entire dependency tree except those two
  // Redux/reducer logic hot reloads itself
  module.hot.accept('./routes', () => {
    // eslint-disable-next-line
    const NextRoutes = require('./routes').default;
    ReactDOM.render(renderApp(NextRoutes), rootElement);
  });
}
