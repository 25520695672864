import { combineReducers } from 'redux';
import { urls, urlsWrapper } from 'config';
import { serialize, sortListByImportantAndName } from 'utils';
import { getAuthUserCountry } from 'utils/getAuthUserCountry';
import { convertDictionaryFilters } from 'utils/convertDictionaryFilters';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const seeds = createCommonListConstants('SEEDS');
const seedItem = createCommonItemConstants('SEED_ITEM');
const vendors = createCommonListConstants('SEED_VENDORS');

// reducer
export default combineReducers({
  seeds: createCommonListReducer(seeds, sortListByImportantAndName),
  seedItem: createCommonItemReducer(seedItem),
  vendors: createCommonListReducer(vendors),
});

// actions
export function getSeeds({ embed = 'none', ...additional }) {
  const params = serialize({ embed, ...additional });
  return saveData(
    `${urls().seeds}?${params}`,
    seeds.SET_LIST,
    seeds.SET_IS_PENDING
  );
}

function getSeedItem(itemId) {
  return commonGet({ url: `${urls().seeds}/${itemId}` });
}

export function getSeedAnaliticsList(params) {
  return commonGet({ url: `${urlsWrapper('getSeedsListAnalitics')}`, params });
}

export function getSeedVendors() {
  return saveData(urls().seedVendors, vendors.SET_LIST, vendors.SET_IS_PENDING);
}

const shouldFetchSeeds = (state) => state.seeds.seeds.list.length === 0;

export const fetchSeedsIfNeeded = (params) => (dispatch, getState) => {
  const state = getState();
  const countries = getAuthUserCountry(
    state.auth.currentCustomer,
    state.auth.currentAdvisor
  );

  return shouldFetchSeeds(state)
    ? dispatch(getSeeds(Object.assign(params || {}, { countries })))
    : Promise.resolve();
};

const shouldFetchSeedVendors = (state) => state.seeds.vendors.list.length === 0;

export const fetchSeedVendorsIfNeeded = () => (dispatch, getState) =>
  shouldFetchSeedVendors(getState())
    ? dispatch(getSeedVendors())
    : Promise.resolve();

export const fetchSeedItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'seeds',
    itemSubStateKey: 'seedItem',
    itemConstants: seedItem,
    getDictItem: getSeedItem,
  });

export const getSeedsIdsBySearch = (query = '', filters = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const countries = getAuthUserCountry(
    state.auth.currentCustomer,
    state.auth.currentAdvisor
  );

  return commonGet({
    url: `${urls().seeds}/search/ids`,
    params: {
      query: query || undefined,
      ...convertDictionaryFilters(filters),
      countries,
    },
  });
};
