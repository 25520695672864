import axios from 'axios';
import http from 'axios/lib/adapters/http';
import xhr from 'axios/lib/adapters/xhr';
import { checkToken, postRefreshToken, getAuthHeader } from 'modules/auth';
import { SubmissionError } from 'redux-form';
import { cleanLocalStorage, prepareError } from 'utils';
import devNotification from 'utils/devNotification';
import { env_mock } from 'env';
import { urls } from 'config';
import { getMockUrl, mockPromise } from './mocks';

let refreshTokenPromise = null; // this holds any in-progress token refresh requests

export const CUSTOM_ERROR_CODES = {
  REPORT_EMPTY: 'ReportEmpty',
  REPORT_BAD_PARAMS: 'ReportBadParams',
  REPORT_TOO_LARGE: 'ReportTooLarge',
  CANCELED: 'CANCELED',
  REFRESH_TOKEN_NOT_FOUND: 'REFRESH_TOKEN_NOT_FOUND',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
};

const CUSTOM_ERROR_MESSAGES = {
  [CUSTOM_ERROR_CODES.REPORT_EMPTY]:
    'Не найдено данных, подходящих под заданные параметры отчета',
  [CUSTOM_ERROR_CODES.REPORT_BAD_PARAMS]:
    'Заданы некорректные параметры отчета',
  [CUSTOM_ERROR_CODES.REPORT_TOO_LARGE]:
    'Вы запросили отчет, который содержит много фотографий. Его размер составит более 100 мегабайтов, создание займет значительное время, и работать с ним будет неудобно.\n' +
    'Попробуйте изменить параметры - например, выбрать поле, отделение или сотрудника. Если вам нужен именно такой отчет, пожалуйста, напишите в поддержку, мы подготовим его для вас.',
};

// actions names map. only for redux actions api
export const axiosEvents = new Map();

const updateEvents = (eventType) => {
  const source = axios.CancelToken.source();
  if (eventType) {
    const event = axiosEvents.get(eventType);
    if (event) {
      axiosEvents.set(eventType, { source, count: event.count + 1 });
      if (event.source) {
        event.source.cancel();
      }
    } else {
      axiosEvents.set(eventType, { source, count: 1 });
    }
  }
  return source;
};

const decEvent = (eventType) => {
  const event = axiosEvents.get(eventType);
  if (event) {
    if (event.count === 1) {
      axiosEvents.delete(eventType);
    } else {
      axiosEvents.set(eventType, { ...event, count: event.count - 1 });
    }
  }
};

const processResponseErrorData = ({ code, message }) =>
  CUSTOM_ERROR_MESSAGES[code] || message;

axios.interceptors.request.use(
  async (config) => {
    if (
      !config.headers.Authorization &&
      config.url.search(urls().login) === -1 &&
      config.url.search(urls().bayertoken) === -1
    ) {
      const token = checkToken();
      if (!token) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          config,
          message: CUSTOM_ERROR_CODES.REFRESH_TOKEN_NOT_FOUND,
        });
      }
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...getAuthHeader(token),
        ...(config.headers || {}),
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error &&
      (error.message === CUSTOM_ERROR_CODES.REFRESH_TOKEN_NOT_FOUND ||
        (error.response &&
          error.response.status === 401 &&
          error.response.config.url.search(urls().login) === -1 &&
          error.response.config.url.search(urls().refreshToken) === -1)) &&
      !error.config._retry
    ) {
      // prevent retry
      // eslint-disable-next-line no-param-reassign
      error.config._retry = true;
      if (!refreshTokenPromise) {
        refreshTokenPromise = postRefreshToken()
          .catch((e) => {
            // SubmissionError
            if (e && e.errors && e.errors.err && e.errors.err.status === 401) {
              cleanLocalStorage();
              window.location.href = '/';
            }
            return Promise.reject(e);
          })
          .finally(() => {
            refreshTokenPromise = null;
          });
      }
      return refreshTokenPromise.then(() => {
        const token = checkToken();
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          ...(token ? getAuthHeader(token) : {}),
        };
        return axios(error.config);
      });
    }
    return Promise.reject(error);
  }
);

export const commonRequest = async ({
  headers: customHeaders,
  errorMessage,
  eventType,
  ...params
}) => {
  // Use mocked responses
  if (String(env_mock) === 'true') {
    const mockUrl = getMockUrl(params.url);
    if (mockUrl) {
      return mockPromise(mockUrl).finally(() => {
        decEvent(eventType);
      });
    }
  }
  // Canceling tokens as of now is only enabled through saveData
  // since only there eventType is passed down to commonRequest
  const source = updateEvents(eventType);
  const headers = customHeaders || {};

  return axios({
    headers,
    ...params,
    // we need to use xhr adapter to bypass FormData in jest
    // https://stackoverflow.com/a/57138333/1018686
    adapter: params.data instanceof FormData ? xhr : http,
    cancelToken: source && source.token,
  })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { error: CUSTOM_ERROR_CODES.CANCELED };
      }
      if (error.response) {
        const err = new Error('Request failed');
        const message = processResponseErrorData(error.response.data);
        err.response = {
          status: error.response.status,
          url: error.response.config.url,
          method: error.response.config.method,
          message,
          originalErrorData: error.response.data,
        };
        devNotification(err);
        throw new SubmissionError(
          prepareError(message, errorMessage, err.response)
        );
      } else if (error.message === 'Network Error') {
        throw new SubmissionError(prepareError(error.request, 'Ошибка сети'));
      } else if (error.request) {
        devNotification(error);
        throw new SubmissionError(prepareError(error.request, errorMessage));
      } else {
        devNotification(error);
        throw new SubmissionError(prepareError(error.message, errorMessage));
      }
    })
    .finally(() => {
      decEvent(eventType);
    });
};

export const commonGet = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось получить ${name}`,
    method: 'GET',
  });

export const commonPost = ({ name = 'объект', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось создать ${name}`,
    method: 'POST',
  });

export const commonPut = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось обновить ${name}`,
    method: 'PUT',
  });

export const commonDelete = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось удалить ${name}`,
    method: 'DELETE',
  });
