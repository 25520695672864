import { createAction } from 'redux-actions';
import { getAdvisorCustomerId, getCustomerId, sortByDateKey } from 'utils';
import moment from 'moment';
import {
  createCommonItemConstants,
  createCommonItemReducer,
  RESET_TYPES,
  resetData,
} from './utils';
import { commonGet } from './api';
import { urlsWrapper } from '../config';
import { getCurrentCustomer } from './auth';

const constantsUnattachedTracks = createCommonItemConstants(
  'UNATTACHED_TRACKS'
);
const constantsUnattachedTracksGeojson = createCommonItemConstants(
  'UNATTACHED_TRACKS_GEOJSON'
);

export const unattachedTracks = createCommonItemReducer(
  constantsUnattachedTracks
);
export const unattachedTracksGeojson = createCommonItemReducer(
  constantsUnattachedTracksGeojson
);

export const getUnattachedTracks = (fieldId) =>
  commonGet({
    url: `${urlsWrapper('geoprocessorFields')}/${fieldId}/extents`,
    params: {
      workrecord_id: 'null',
      machine_type:
        'UNKNOWN,HARVESTER,TRACTOR,SPRAYER,RIDING_MOWER,LOADER,GRADER,CASTRATOR',
    },
  });

export function resetUnattachedTracks() {
  return resetData(constantsUnattachedTracks.SET_ITEM, RESET_TYPES.ITEM);
}

export const getUnattachedTracksGeojson = (extentId) =>
  commonGet({
    url: `${urlsWrapper('geoprocessorExtents')}/${extentId}/geojson`,
  });

const groupTracksByYearAndDate = (list) => {
  const seasonList = {
    [+list[0].start_dt.substr(0, 4)]: [[list[0]]],
  };
  const { length } = list;
  for (let i = 1; i < length; i++) {
    const date = list[i].start_dt.substr(0, 10);
    const prevDate = {
      start: list[i - 1].start_dt.substr(0, 10),
      end: list[i - 1].end_dt.substr(0, 10),
    };
    const year = +list[i].start_dt.substr(0, 4);
    if (typeof seasonList[year] !== 'undefined') {
      if (
        moment(date).isSame(prevDate.start, 'day') ||
        moment(date).subtract(1, 'day').isSame(prevDate.start, 'day') ||
        moment(date).isSame(prevDate.end, 'day') ||
        moment(date).subtract(1, 'day').isSame(prevDate.end, 'day')
      ) {
        const l = seasonList[year].length;
        seasonList[year][l - 1] = [...seasonList[year][l - 1], list[i]];
      } else {
        seasonList[year].push([list[i]]);
      }
    } else {
      seasonList[year] = [[list[i]]];
    }
  }
  return seasonList;
};

export const getAllUnattachedTracks = (fieldId) => async (dispatch) => {
  try {
    const customerId = getAdvisorCustomerId() || getCustomerId();
    const { geo_enabled } =
      (await dispatch(getCurrentCustomer(customerId))) || {};
    if (geo_enabled) {
      dispatch(createAction(constantsUnattachedTracks.SET_IS_PENDING)(true));
      const tracksResponse = await getUnattachedTracks(fieldId);
      const resultTracks = sortByDateKey(
        tracksResponse?.data?.data || [],
        'start_dt'
      );

      const result = {
        [fieldId]: resultTracks?.length
          ? groupTracksByYearAndDate(resultTracks)
          : [],
      };
      dispatch(createAction(constantsUnattachedTracks.SET_ITEM)(result));
    }
  } catch (e) {
    dispatch(resetUnattachedTracks());
    throw e;
  } finally {
    dispatch(createAction(constantsUnattachedTracks.SET_IS_PENDING)(false));
  }
};
