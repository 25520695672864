import { env_externalservices } from 'env';
import componentLoader from 'utils/componentLoader';
// import Divisions from 'components/customer/Divisions/';

const Rotation = componentLoader(() =>
  import(
    /* webpackChunkName: "common-Rotation" */ 'components/common/Rotation/'
  )
);
const SeasonPlans = componentLoader(() =>
  import(
    /* webpackChunkName: "common-SeasonPlans" */ 'components/common/SeasonPlans/'
  )
);
const Scouting = componentLoader(() =>
  import(
    /* webpackChunkName: "customer-Scouting" */ 'components/customer/Scouting/'
  )
);
const Dictionaries = componentLoader(() =>
  import(
    /* webpackChunkName: "common-Dictionaries" */ 'components/common/Dictionaries/'
  )
);
const Map = componentLoader(() =>
  import(/* webpackChunkName: "customer-Map" */ 'components/customer/Map/')
);
const Weather = componentLoader(() =>
  import(
    /* webpackChunkName: "customer-Weather" */ 'components/customer/Weather/'
  )
);
const Customers = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-Customers" */ 'components/advisor/Customers'
  )
);
const Analytics = componentLoader(() =>
  import(
    /* webpackChunkName: "customer-Analytics" */ 'components/customer/Analytics'
  )
);
const Works = componentLoader(() =>
  import(/* webpackChunkName: "customer-Works" */ 'components/customer/Works')
);
const Settings = componentLoader(() =>
  import(
    /* webpackChunkName: "customer-Settings" */ 'components/customer/Settings'
  )
);

const ScoutsRecommendations = componentLoader(() =>
  /* webpackChunkName: "customer-Recommendations" */ import(
    'components/customer/Scouting/Recommendations'
  )
);

const childrenRoutes = [
  {
    title: 'Клиенты',
    path: '/advisor',
    component: Customers,
    exact: true,
    icon: 'fa fa-sitemap',
    advisor: true,
  },
  {
    title: 'Карта',
    path: '/customer/:customerId',
    component: Map,
    exact: true,
    icon: 'fa fa-map',
  },
  {
    title: 'Севооборот',
    path: '/customer/:customerId/rotation',
    component: Rotation,
    exact: false,
    icon: 'fa fa-chart-pie',
  },
  {
    title: 'Работы',
    path: '/customer/:customerId/works',
    component: Works,
    exact: true,
    icon: 'fa fa-tractor',
  },
  {
    title: 'Техкарты',
    path: '/customer/:customerId/seasonplans',
    component: SeasonPlans,
    exact: false,
    icon: 'fa fa-project-diagram',
  },
  {
    title: 'Осмотры',
    path: '/customer/:customerId/scouting',
    component: Scouting,
    exact: false,
    icon: 'fa fa-eye',
  },
  {
    title: 'Рекомендации',
    path: '/customer/:customerId/recommendations',
    component: ScoutsRecommendations,
    exact: false,
    icon: 'fas fa-comment-medical',
  },
  {
    title: 'Погода',
    path: '/customer/:customerId/weather',
    component: Weather,
    exact: false,
    icon: 'fa fa-cloud',
    hideSection: () => env_externalservices !== 'true',
  },
  // {
  //   title: 'Хозяйства',
  //   path: '/divisions',
  //   component: Divisions,
  //   exact: false,
  //   icon: 'fa fa-sitemap',
  // },
  {
    title: 'Справочники',
    path: '/customer/:customerId/dict',
    component: Dictionaries,
    exact: false,
    icon: 'fa fa-list',
  },
  {
    title: 'Аналитика',
    path: '/customer/:customerId/analytics',
    component: Analytics,
    exact: false,
    icon: 'fa fa-chart-bar',
  },
  {
    title: 'Настройки',
    path: '/customer/:customerId/settings',
    component: Settings,
    hideForNotAdmins: true,
    exact: false,
    icon: 'fa fa-cog',
  },
];

/**
 * Possible values for children object:
 * title - title of the section
 * path - url path to the section
 * component - component to render
 * exact - render child route components on it's own urls
 * icon - icon to show in a menu
 * hideSection - hide or show section for production
 * hideForNotAdmins - hide or show section for non admin users
 * hideOnEmpty - hide or show section if no content available
 */

export default [
  {
    title: '',
    children: childrenRoutes,
  },
];
