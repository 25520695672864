import { createAction } from 'redux-actions';
import { commonGet, axiosEvents } from './api';

export const RESET_TYPES = {
  LIST: 'LIST',
  ITEM: 'ITEM',
};

export const createCommonListConstants = (listName = '') => {
  const listNameUpper = listName.toUpperCase();
  return {
    SET_LIST: `${listNameUpper}_SET_LIST`,
    SET_IS_PENDING: `${listNameUpper}_SET_IS_PENDING`,
  };
};

export const createCommonItemConstants = (itemName = '') => {
  const itemNameUpper = itemName.toUpperCase();
  return {
    SET_ITEM: `${itemNameUpper}_SET_ITEM`,
    SET_IS_PENDING: `${itemNameUpper}_SET_IS_PENDING`,
  };
};

export const createCommonListReducer = (actionTypes = {}, transform) => {
  const defaultState = {
    list: [],
    isPending: false,
  };

  return (state = defaultState, action) => {
    switch (action.type) {
      case actionTypes.SET_LIST:
        return {
          ...state,
          list: transform ? transform(action.payload) : action.payload,
          isPending: false,
        };
      case actionTypes.SET_IS_PENDING:
        return { ...state, isPending: action.payload };
      default:
        return state;
    }
  };
};

export const createCommonItemReducer = (actionTypes = {}, transform) => {
  const defaultState = {
    item: {},
    isPending: false,
  };

  return (state = defaultState, action) => {
    switch (action.type) {
      case actionTypes.SET_ITEM:
        return {
          ...state,
          item: transform ? transform(action.payload) : action.payload,
        };
      case actionTypes.SET_IS_PENDING:
        return { ...state, isPending: action.payload };
      default:
        return state;
    }
  };
};

export const saveData = (url, eventType, isPendingType, resetType) => async (
  dispatch
) => {
  if (isPendingType) {
    dispatch(createAction(isPendingType)(true));
  }
  try {
    const { data } = await commonGet({ url, eventType });
    if (eventType && data) {
      dispatch(createAction(eventType)(data));
    }
    return data;
  } catch (e) {
    if (resetType) {
      dispatch(
        createAction(eventType)(resetType === RESET_TYPES.LIST ? [] : {})
      );
    }
    return Promise.reject(e);
  } finally {
    if (isPendingType && !axiosEvents.get(eventType)) {
      dispatch(createAction(isPendingType)(false));
    }
  }
};

export const resetData = (eventType, resetType) => (dispatch) => {
  dispatch(createAction(eventType)(resetType === RESET_TYPES.LIST ? [] : {}));
};

export const fetchDictionariesItemIfNotExist = ({
  itemId,
  itemStateKey,
  itemSubStateKey,
  itemConstants,
  getDictItem,
}) => async (dispatch, getState) => {
  const itemData = itemSubStateKey
    ? getState()[itemStateKey][itemSubStateKey].item[itemId]
    : getState()[itemStateKey].item[itemId];
  if (!itemData) {
    try {
      dispatch(createAction(itemConstants.SET_IS_PENDING)(true));
      const item = await getDictItem(itemId);
      const previousState = itemSubStateKey
        ? getState()[itemStateKey][itemSubStateKey].item
        : getState()[itemStateKey].item;
      const result = {
        ...previousState,
        [itemId]: item.data,
      };
      dispatch(createAction(itemConstants.SET_ITEM)(result));
    } finally {
      dispatch(createAction(itemConstants.SET_IS_PENDING)(false));
    }
  }
};
