import React, { Suspense } from 'react';
import componentLoader from 'utils/componentLoader';
import { isAdmin, getAdvisorId, getAdvisorCustomerId } from 'utils';
import { connect } from 'react-redux';
import isFunction from 'lodash.isfunction';
import { Route, Switch, NavLink, Redirect, withRouter } from 'react-router-dom';
import { env_type } from 'env';
import Loader from 'components/common/Loader';
import Header from 'components/common/layout/Header';
import routes from './routes';
import {
  getCurrentAdvisorCustomerSelector,
  setAdvisorCustomerToState,
} from '../modules/currentAdvisorCustomer';

const FieldContainer = componentLoader(() =>
  import(
    /* webpackChunkName: "field-FieldContainer" */ 'components/field/FieldContainer'
  )
);
const ProfileView = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-BayerTsProfileView" */ 'components/advisor/Profile'
  )
);
const BayerTsProfileView = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-ProfileView" */ 'components/advisor/Profile/BayerTsProfileView'
  )
);
const Workrecords = componentLoader(() =>
  import(
    /* webpackChunkName: "customer-Workrecords" */ 'components/customer/Workrecords'
  )
);

const isHide = (tab) =>
  isFunction(tab.hideSection) ? tab.hideSection() : tab.hideSection;

const isNotHidden = (section, array = []) => {
  if (env_type === 'production' && section.staging) return false;
  if (section.hideForNotAdmins && !isAdmin()) return false;
  if (isHide(section)) return false;
  if (section.hideOnEmpty && array.length === 0) return false;
  return true;
};

const isAdvisorShown = (customerId) => {
  const isAdvisor = !!getAdvisorId();
  const isAdvisorCustomerId = customerId || !!getAdvisorCustomerId();
  return isAdvisorCustomerId && isAdvisor;
};

const isAdvisorSectionShown = (section, customerId) => {
  if (isAdvisorShown(customerId) && section.advisor) {
    return true;
  }
  return !section.advisor;
};

const Notfound = ({ topics }) => {
  const filteredRoutes = routes.map((section) =>
    section.children.filter((option) => isNotHidden(option, topics))
  );
  return filteredRoutes.length && filteredRoutes[0].path ? (
    <Redirect to={filteredRoutes[0].path} />
  ) : null;
};

const prepareCustomerPath = (path, customerId) =>
  customerId ? path.replace(':customerId', customerId) : path;

const SectionNav = (props) => (
  <div>
    {props.title && <h4 className="page__nav__heading">{props.title}</h4>}
    <ul
      className={`nav nav-pills ${
        props.isCollapsed ? 'nav-pills--collapsed' : ''
      } nav-stacked`}
    >
      {props.childrenRoutes.map((option) =>
        isNotHidden(option, props.topics) &&
        isAdvisorSectionShown(option, props.customerId) ? (
          <li className="nav__item" key={option.path}>
            <NavLink
              to={prepareCustomerPath(option.path, props.customerId)}
              activeClassName="active"
              exact={option.exact}
            >
              <i className={option.icon} />
              {option.customTitle ? (
                <option.customTitle title={option.title} />
              ) : (
                option.title
              )}
            </NavLink>
          </li>
        ) : (
          ''
        )
      )}
    </ul>
  </div>
);

class RoutesWrapper extends React.Component {
  state = {
    isCollapsed: localStorage.getItem('IS_MENU_COLLAPSED') == 'true',
  };

  handleClick = () => {
    this.setState(({ isCollapsed }) => {
      localStorage.setItem('IS_MENU_COLLAPSED', !isCollapsed);
      return {
        isCollapsed: !isCollapsed,
      };
    });
  };

  render() {
    const {
      menu,
      topics,
      match,
      customer,
      currentAdvisorCustomer,
    } = this.props;
    const { customerId } = match.params;
    const isWaitCustomerInState = customer
      ? currentAdvisorCustomer.isPending || !currentAdvisorCustomer.item.id
      : false;

    return (
      <>
        <Header />
        <div className="page__content-wrapper">
          <nav className="page__nav">
            <div className="page-nav">
              {menu.map((section) =>
                isNotHidden(section) ? (
                  <SectionNav
                    key={section.title}
                    title={section.title}
                    childrenRoutes={section.children}
                    isCollapsed={this.state.isCollapsed}
                    topics={topics}
                    customerId={customerId}
                  />
                ) : (
                  ''
                )
              )}
              <div
                className="page-nav__collapse"
                onClick={this.handleClick}
                role="button"
                tabIndex={0}
              >
                <i
                  className={`fa ${
                    this.state.isCollapsed
                      ? 'fa-arrow-alt-circle-right'
                      : 'fa-arrow-alt-circle-left'
                  }`}
                />
              </div>
            </div>
          </nav>

          {!isWaitCustomerInState && (
            <div className="page__content">
              <Suspense fallback={<Loader />}>
                <Switch>
                  {menu.map((section) =>
                    [...section.children].map((option) =>
                      isNotHidden(option, topics) &&
                      isAdvisorSectionShown(option, customerId) ? (
                        <Route
                          exact={option.exact}
                          path={option.path}
                          component={option.component}
                          key={`${section.title}-${option.path}`}
                        />
                      ) : (
                        ''
                      )
                    )
                  )}
                  {isAdvisorShown(customerId) && (
                    <Route
                      path={`/customer/${customerId}/profile`}
                      component={ProfileView}
                      key="profile"
                    />
                  )}
                  <Route
                    path="/advisor/profile/:profileId"
                    component={BayerTsProfileView}
                    key="visits-profile"
                  />
                  <Route
                    path={`/customer/${customerId}/fields/:fieldId([0-9]+)`}
                    component={FieldContainer}
                    key="field"
                  />
                  <Route
                    path={`/customer/${customerId}/works/:cropId([0-9]+)/:workTypeGroupValue([0-9]+)`}
                    component={Workrecords}
                    key="workrecords"
                  />
                  <Route
                    render={(props) => <Notfound topics={topics} {...props} />}
                  />
                </Switch>
              </Suspense>
            </div>
          )}
        </div>
      </>
    );
  }
}

const routesWrapper = connect(
  (state) => ({
    currentAdvisorCustomer: getCurrentAdvisorCustomerSelector(state),
  }),
  { setAdvisorCustomerToState }
)(RoutesWrapper);

export default withRouter(routesWrapper);
