import React from 'react';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { getItemById } from 'utils';
import getSowingCropId, { getSowing } from 'utils/getSowingCropId';
import ListItem from 'components/common/ListItem';
import VirtualisedList, {
  DEFAULT_ROW_HEIGHT,
} from 'components/common/VirtualisedList';
import getFieldAreaInfo from 'utils/getFieldAreaInfo';
import getFieldPlotInfo from 'utils/getFieldPlotInfo';

const ROW_VIRTUAL_LIST_MULTIPLIER = 22;

const getProductKey = (productId) => {
  switch (productId) {
    case 'relief':
      return 'relief_avg';
    case 'contrast_ndvi_masked':
      return 'std_ndvi';
    default:
      return 'avg_ndvi';
  }
};

class FieldList extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.activeFieldId !== prevProps.activeFieldId) {
      const domNode = this.list.querySelector('.active');
      if (this.list && domNode) {
        domNode.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  componentWillUnmount() {
    this.onHoverDebounced?.cancel();
  }

  onHoverDebounced = debounce((isHovered, field) => {
    this.props.onHover?.(isHovered, field.id);
  }, 50);

  getDescription = (field) => {
    const { crops, globalFilterSeasons } = this.props;
    const sowing = getSowing(field, globalFilterSeasons);
    const crop = getItemById(crops, sowing?.crop_id);

    const cropInfo = crop == null ? 'Культура не указана' : crop.name;
    const plotInfo = getFieldPlotInfo(sowing);
    return [`${cropInfo}${getFieldAreaInfo(field, true)}`].concat(
      plotInfo || []
    );
  };

  calculateRowHeight = (index, fields) => {
    const field = fields[index];
    if (!field) {
      return DEFAULT_ROW_HEIGHT;
    }

    const description = this.getDescription(field);
    return (
      DEFAULT_ROW_HEIGHT +
      (Array.isArray(description) && description.length
        ? (description.length - 1) * ROW_VIRTUAL_LIST_MULTIPLIER
        : 0)
    );
  };

  rowRenderer = ({ key, index, style, list }) => {
    const {
      globalFilterSeasons,
      handleClick,
      activeFieldId,
      satProductsIsShow,
      satProductsByDivisionIsPending,
      customerId,
      productId,
    } = this.props;
    const fieldKey = getProductKey(productId);
    const field = list[index];
    const description = this.getDescription(field);

    return (
      <ListItem
        onHover={(...params) => this.onHoverDebounced(...params, field)}
        key={key}
        style={style}
        name={
          field ? (
            <div className={satProductsIsShow ? 'nowrap ellipsis' : ''}>
              <Link to={`/customer/${customerId}/fields/${field.id}`}>
                {field.name}
              </Link>
            </div>
          ) : null
        }
        description={description}
        // photo_path={getFieldIconPath(field, globalFilterSeasons)}
        svgIconPath={field.svg_icon_path}
        isBorderless={!field.area}
        sowing={getSowing(field, globalFilterSeasons)}
        isStaticPhotoPath
        isActive={field.id === activeFieldId}
        handleItemSelect={() => handleClick(field.id)}
        avgNdvi={field[fieldKey]}
        productId={productId}
        isAvgNvdiShow={satProductsIsShow}
        avgNdviIsPending={satProductsByDivisionIsPending}
      />
    );
  };

  render() {
    const {
      fields,
      crops,
      productId,
      globalFilterSeasons,
      satProductsByDivision,
      satProductsIsShow,
      ndviSortDirection,
    } = this.props;

    const key = getProductKey(productId);
    const satProductsAvgNdvi = {};

    if (satProductsIsShow && satProductsByDivision) {
      satProductsByDivision.forEach((item) => {
        satProductsAvgNdvi[item.field_id] =
          (item[key] && (+item[key]).toFixed(2)) || null;
      });
    }
    const fieldsFiltered = [];
    const fieldsFilteredNvdiNull = [];

    fields.forEach((field) => {
      const crop = crops.find(
        (c) => c.id === getSowingCropId(field, globalFilterSeasons)
      );
      const cropName = crop ? crop.name : '';
      if (ndviSortDirection && satProductsAvgNdvi[field.id]) {
        fieldsFiltered.push({
          crop_name: cropName,
          [key]: satProductsAvgNdvi[field.id],
          ...field,
        });
      } else {
        fieldsFilteredNvdiNull.push({
          crop_name: cropName,
          [key]: satProductsAvgNdvi[field.id],
          ...field,
        });
      }
    });

    const fieldsSortedBy = [...fieldsFiltered].sort((a, b) => {
      let result = a.name.localeCompare(b.name);
      if (ndviSortDirection === 'asc') {
        result = a[key] - b[key];
      } else if (ndviSortDirection === 'desc') {
        result = b[key] - a[key];
      }
      return result;
    });

    const fieldsFilteredNvdiNullSorted = [
      ...fieldsFilteredNvdiNull,
    ].sort((a, b) => a.name.localeCompare(b.name));

    const resultFieldList = [
      ...fieldsSortedBy,
      ...fieldsFilteredNvdiNullSorted,
    ];

    return (
      <ul
        className="list-group"
        ref={(ref) => {
          this.list = ref;
        }}
      >
        <VirtualisedList
          rowHeight={({ index }) =>
            this.calculateRowHeight(index, resultFieldList)
          }
          list={resultFieldList}
          rowRenderer={this.rowRenderer}
        />
      </ul>
    );
  }
}

export default FieldList;
