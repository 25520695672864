import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import { getTracksByWorkrecords } from 'modules/workRecords';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('GLOBAL_WORKRECORDS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getGlobalWorkrecords(params) {
  return saveData(
    `${urlsWrapper('workrecords')}?${serialize(params)}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const getGlobalWorkrecordsWithTracks = (params) => async (dispatch) => {
  const workrecords = await dispatch(
    saveData(
      `${urlsWrapper('workrecords')}?${serialize(params)}`,
      constants.SET_LIST,
      constants.SET_IS_PENDING
    )
  );
  return dispatch(getTracksByWorkrecords(workrecords, constants));
};
