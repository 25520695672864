import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldTabsSelector, setMapFilters } from 'modules/mapFilters';
import { getFilterSeasonSelector } from 'modules/globalFilters';
import FieldListTab from './FieldListTab';

export const TABS = {
  ALL: 'ALL',
  ACTUAL: 'ACTUAL',
};

const FieldListTabs = () => {
  const dispatch = useDispatch();
  const activeTabName = useSelector(getFieldTabsSelector);
  const season = useSelector(getFilterSeasonSelector);

  const onChangeTabCb = useCallback((fieldsTab) => {
    dispatch(setMapFilters({ fieldsTab }));
  }, []);

  const tabs = useMemo(() => {
    const currentYear =
      Number.parseInt(season, 10) || moment(new Date()).year();
    return [
      {
        title: 'Все поля',
        id: TABS.ALL,
      },
      {
        title: `Только сезон ${currentYear}`,
        id: TABS.ACTUAL,
      },
    ];
  }, [season]);

  return (
    <div className="field-list-tabs__wrapper">
      {tabs.map(({ title, id }, index) => (
        <FieldListTab
          tabIndex={index}
          id={id}
          isActive={id === activeTabName}
          onChangeTabCb={onChangeTabCb}
          title={title}
          key={id}
        />
      ))}
    </div>
  );
};

export default React.memo(FieldListTabs);
