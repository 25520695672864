import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';

const getIcon = (type) => {
  switch (type) {
    case 1:
      return 'fa-eye';
    case 2:
      return 'fa-exclamation-triangle';
    case 3:
      return 'fa-comment';
    case 4:
      return 'fa-comment-medical';
    case 5:
      return (
        <svg
          style={{ fill: '#9b9b9b' }}
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM288 176c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 48.8 46.5 111.6 68.6 138.6c6 7.3 16.8 7.3 22.7 0c22.1-27 68.6-89.8 68.6-138.6zm-112 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
        </svg>
      );
    default:
      return '';
  }
};

const Notification = ({
  notification: {
    created_at,
    notification_type,
    customer_id,
    scout_plan_id,
    description,
    title,
    id,
    data: { field_id, scout_id },
  },
  handleNotificationClick,
  handleCloseClick,
}) => {
  const [isCloseClicked, setIsClosedClicked] = useState(false);

  const clickCb = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isCloseClicked) {
        setIsClosedClicked(true);
        handleCloseClick(id);
      }
    },
    [isCloseClicked, handleCloseClick]
  );

  const notificationClass = useMemo(
    () => (isCloseClicked ? 'fa-spinner fa-spin' : 'fa-times'),
    [isCloseClicked]
  );

  const date = useMemo(() => moment(created_at).format('DD.MM.YY, HH:mm'), [
    created_at,
  ]);

  const icon = useMemo(() => getIcon(notification_type), [notification_type]);
  return (
    <Dropdown.Item
      eventKey={id}
      key={id}
      onClick={() =>
        handleNotificationClick({
          id,
          fieldId: field_id,
          scoutId: scout_id,
          customerId: customer_id,
          notificationType: notification_type,
          scoutPlanId: scout_plan_id,
        })
      }
      bsPrefix="notification__wrap"
    >
      <div className="notification">
        <div className="notification__icon">
          {typeof icon !== 'string' ? icon : <i className={`fa ${icon}`} />}
        </div>
        <div className="notification__main">
          <div className="notification__title">{title}</div>
          <div className="notification__text">{description}</div>
          <div className="notification__date">{date}</div>
        </div>
        <div
          className="notification__icon notification__icon--close"
          onClick={clickCb}
          role="button"
          tabIndex={0}
        >
          <i className={`fa ${notificationClass}`} />
        </div>
      </div>
    </Dropdown.Item>
  );
};

export default React.memo(Notification);
