import React from 'react';
import Loader from 'components/common/Loader';
import FieldList from 'components/customer/Map/FieldList';
import unfocusOnEnter from 'utils/unfocusOnEnter';
import InputUi from 'components/common/Input/InputUi';

class FieldSelectPanel extends React.PureComponent {
  state = {
    fieldNameToFilter: '',
  };

  handleInputChange = (value) => {
    this.setState({ fieldNameToFilter: value });
  };

  handleClick = (fieldId) => {
    if (fieldId) {
      this.props.history.push(
        `/customer/${this.props.customerId}/fields/${fieldId}`
      );
    }
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  render() {
    const {
      fields,
      crops,
      divisions,
      globalFilterSeasons,
      globalFilterDivisions,
    } = this.props;
    const { fieldNameToFilter } = this.state;

    const fieldsFiltered = fields.list.filter(
      (field) =>
        field.name.toLowerCase().indexOf(fieldNameToFilter.toLowerCase()) > -1
    );

    return (
      <div className="sidebar field-select-panel">
        <div className="sidebar__header">
          <div className="add-field">
            <InputUi
              classNameWrap="add-field-input"
              value={this.state.fieldNameToFilter}
              onChange={this.handleInputChange}
              placeholder="Поиск по названию..."
              isClearable
              onKeyDown={unfocusOnEnter}
            />
          </div>
        </div>
        <div className="sidebar__main">
          {fields.isPending || crops.isPending ? (
            <Loader />
          ) : fieldsFiltered.length ? (
            <FieldList
              fields={fieldsFiltered}
              crops={crops.list}
              divisions={divisions}
              customerId={this.props.customerId}
              globalFilterDivisions={globalFilterDivisions}
              globalFilterSeasons={globalFilterSeasons}
              handleClick={this.handleClick}
            />
          ) : (
            <p className="not-found">Полей нет</p>
          )}
        </div>
      </div>
    );
  }
}

export default FieldSelectPanel;
