import { combineReducers } from 'redux';
import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import { commonGet } from 'modules/api';
import {
  saveData,
  createCommonItemConstants,
  createCommonItemReducer,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const scoutsConstants = createCommonItemConstants('ANALYTICS_SCOUTS');
const cropsConstants = createCommonItemConstants('ANALYTICS_CROPS');
const cropsByIdConstants = createCommonItemConstants('ANALYTICS_CROPS_BY_ID');
const cropsScoutProblemsConstants = createCommonItemConstants(
  'ANALYTICS_CROPS_SCOUTPROBLEMS'
);
const usedMaterialConstants = createCommonItemConstants(
  'ANALYTICS_USED_MATERIAL_BY_CROPS_ID'
);
const workQualityConstants = createCommonListConstants(
  'ANALYTICS_WORK_QUALITY'
);

// reducer
const scouts = createCommonItemReducer(scoutsConstants);
const crops = createCommonItemReducer(cropsConstants);
const cropsById = createCommonItemReducer(cropsByIdConstants);
const cropsScoutProblems = createCommonItemReducer(cropsScoutProblemsConstants);
const usedMaterial = createCommonItemReducer(usedMaterialConstants);
const workQuality = createCommonListReducer(workQualityConstants);

export default combineReducers({
  scouts,
  crops,
  cropsById,
  cropsScoutProblems,
  usedMaterial,
  workQuality,
});

// actions
export function getAnalyticsScouts(params) {
  return saveData(
    `${urlsWrapper('analyticsScouts')}?${serialize(params)}`,
    scoutsConstants.SET_ITEM,
    scoutsConstants.SET_IS_PENDING
  );
}

export function getAnalyticsCrops(params) {
  return saveData(
    `${urlsWrapper('analyticsCrops')}?${serialize(params)}`,
    cropsConstants.SET_ITEM,
    cropsConstants.SET_IS_PENDING
  );
}

export function getCropsWithoutSave(params) {
  return commonGet({
    url: `${urlsWrapper('analyticsCrops')}?${serialize(params)}`,
  });
}

export function getAnalyticsCropsById(cropId, params) {
  return saveData(
    `${urlsWrapper('analyticsCrops')}/${cropId}?${serialize(params)}`,
    cropsByIdConstants.SET_ITEM,
    cropsByIdConstants.SET_IS_PENDING
  );
}

export const getAnalyticsCropsScoutProblems = ({ crop_id, ...params }) =>
  saveData(
    `${urlsWrapper('analyticsCrops')}/${crop_id}/scoutproblems?${serialize(
      params
    )}`,
    cropsScoutProblemsConstants.SET_ITEM,
    cropsScoutProblemsConstants.SET_IS_PENDING
  );

export function getAnalyticsUsedMaterialByCrop(cropId, params) {
  return saveData(
    `${urlsWrapper('analyticsCrops')}/${cropId}/inputs?${serialize(params)}`,
    usedMaterialConstants.SET_ITEM,
    usedMaterialConstants.SET_IS_PENDING
  );
}

export function getAnalyticsWorkQuality(cropId, params) {
  return saveData(
    `${urlsWrapper('analyticsCrops')}/${cropId}/workquality?${serialize(
      params
    )}`,
    workQualityConstants.SET_LIST,
    workQualityConstants.SET_IS_PENDING
  );
}

export const getFilledTstrials = (params) =>
  commonGet({
    url: `${urlsWrapper('filledTstrials')}`,
    params,
  });

export const getFilledResultsTstrials = (params) =>
  commonGet({
    url: `${urlsWrapper('filledTstrialsResults')}`,
    params,
  });

export const getFilledProductsTstrials = (params) =>
  commonGet({
    url: `${urlsWrapper('filledTstrialsProducts')}`,
    params,
  });

export const getFilledDivisionTstrials = ({ selectedDivision, ...params }) =>
  commonGet({
    url: `${urlsWrapper('divisions')}/${selectedDivision}/tstrials`,
    params,
  });

export const getTwoBreedsGraphData = (params) =>
  commonGet({
    url: `${urlsWrapper('twoBreedsCompare')}`,
    params,
  });

export const getCompetitorsGraphData = (params) =>
  commonGet({
    url: `${urlsWrapper('competitorsCompare')}`,
    params,
  });
