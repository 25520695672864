import { urls } from 'config';
import {
  isEmailValid,
  storeTokens,
  isAccessTokenExpired,
  updateAccessToken,
} from 'utils';
import { SubmissionError } from 'redux-form';
import { createSelector } from 'reselect';
import { env_externalservices } from 'env';
import { getAuthUserCountry } from 'utils/getAuthUserCountry';
import Chatra from '@chatra/chatra';
import { commonPost, CUSTOM_ERROR_CODES } from './api';
import { saveData } from './utils';

export const auth = {
  SET_LOGOUT: 'AUTH_SET_LOGOUT',
  SET_CURRENT_USER: 'AUTH_SET_CURRENT_USER',
  SET_CURRENT_CUSTOMER: 'AUTH_SET_CURRENT_CUSTOMER',
  SET_CURRENT_ADVISOR: 'AUTH_SET_CURRENT_ADVISOR',
};

const initChatraIntegration = (user) => {
  Chatra('setIntegrationData', {
    /* main properties */
    name: `${user?.last_name || ''} ${user?.first_name || ''}`,
    email: user?.email || '',
  });
};

// reducer
const defaultState = {
  currentUser: {},
  currentCustomer: {},
  currentAdvisor: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case auth.SET_CURRENT_USER: {
      if (env_externalservices === 'true') {
        initChatraIntegration(action.payload);
      }

      return { ...state, currentUser: action.payload };
    }
    case auth.SET_CURRENT_CUSTOMER:
      return { ...state, currentCustomer: action.payload };
    case auth.SET_CURRENT_ADVISOR:
      return { ...state, currentAdvisor: action.payload };
    case auth.SET_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

// actions
export function setLogout() {
  return {
    type: auth.SET_LOGOUT,
  };
}

const commonLogin = ({ url, headers }) => async (dispatch) => {
  const data = await commonPost({
    url,
    headers,
    errorMessage: 'Ошибка авторизации',
  });
  await storeTokens(data.data, dispatch);
  return data;
};

// selector
export const getCurrentUserSelector = createSelector(
  [(state) => state.auth.currentUser],
  (currentUser) => currentUser
);

export const getAuthUserCountrySelector = createSelector(
  [(state) => state.auth.currentCustomer, (state) => state.auth.currentAdvisor],
  getAuthUserCountry
);

// Request JWT
export function login({ login: loginValue, password }) {
  if (!isEmailValid(loginValue))
    throw new SubmissionError({ _error: 'Введен некорректный email' });
  if (!password)
    throw new SubmissionError({ _error: 'Введен некорректный пароль' });

  const authStr = `Basic ${Buffer.from(
    unescape(encodeURIComponent(`${loginValue}:${password}`))
  ).toString('base64')}`;

  return commonLogin({
    url: urls().login,
    headers: { Authorization: authStr },
  });
}

export function bayerLogin() {
  return commonLogin({ url: urls().bayertoken, headers: {} });
}

export const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });

export const postRefreshToken = async () => {
  const refreshToken = localStorage.getItem('JWT_REFRESH');
  if (refreshToken) {
    const { data } = await commonPost({
      url: urls().refreshToken,
      headers: getAuthHeader(refreshToken),
      errorMessage: 'Ошибка',
    });
    updateAccessToken(data);
    return Promise.resolve(data.access_token);
  }
  return Promise.reject(new Error(CUSTOM_ERROR_CODES.REFRESH_TOKEN_NOT_FOUND));
};

export const checkToken = () =>
  isAccessTokenExpired() ? null : localStorage.getItem('JWT_ACCESS');

export function getCurrentUser(id) {
  return saveData(`${urls().authUsers}/${id}`, auth.SET_CURRENT_USER);
}

export function getCurrentCustomer(id) {
  return saveData(`${urls().customers}/${id}`, auth.SET_CURRENT_CUSTOMER);
}

export function getCurrentAdvisor(id) {
  return saveData(`${urls().advisors}/${id}`, auth.SET_CURRENT_ADVISOR);
}

// selectors
export const getAuthCurrentUserSelector = createSelector(
  [(state) => state.auth.currentUser],
  (currentUser) => currentUser
);
