import { urlsWrapper } from 'config';
import { getAdvisorCustomerId, getCustomerId } from 'utils';
import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { createCommonItemReducer, createCommonItemConstants } from './utils';
import { commonDelete, commonGet, commonPost, commonPut } from './api';

export const machineTypesEnum = {
  TRACTOR: 'TRACTOR',
  HARVESTER: 'HARVESTER',
  SPRAYER: 'SPRAYER',
  AUTOMOBILE: 'AUTOMOBILE',
  SPREADER: 'SPREADER',
  RIDING_MOWER: 'RIDING_MOWER',
  LOADER: 'LOADER',
  GRADER: 'GRADER',
  CASTRATOR: 'CASTRATOR',
};

export const machineTypes = {
  [machineTypesEnum.TRACTOR]: 'Трактор',
  [machineTypesEnum.HARVESTER]: 'Комбайн',
  [machineTypesEnum.SPRAYER]: 'Опрыскиватель',
  [machineTypesEnum.AUTOMOBILE]: 'Автомобиль',
  [machineTypesEnum.SPREADER]: 'Разбрасыватель',
  [machineTypesEnum.RIDING_MOWER]: 'Самоходная косилка',
  [machineTypesEnum.LOADER]: 'Погрузчик',
  [machineTypesEnum.GRADER]: 'Грейдер',
  [machineTypesEnum.CASTRATOR]: 'Кастрационная машина',
};

const itemConstants = createCommonItemConstants('MACHINES_ITEM');

// reducer
export const machines = createCommonItemReducer(itemConstants);

// actions
export const getMachines = () =>
  commonGet({
    url: `${urlsWrapper('machines')}`,
  });

export const addMachine = (data) =>
  commonPost({
    url: `${urlsWrapper('machines')}`,
    data,
  });

export const updateMachine = (data, id) =>
  commonPut({
    url: `${urlsWrapper('machines')}/${id}`,
    data,
  });

export const deleteMachine = (id) =>
  commonDelete({
    url: `${urlsWrapper('machines')}/${id}`,
  });

export const addTrackerMachine = (params, machineId) =>
  commonPost({
    url: `${urlsWrapper('machines')}/${machineId}/tracker`,
    params,
  });

export const deleteTrackerMachine = (machineId) =>
  commonDelete({
    url: `${urlsWrapper('machines')}/${machineId}/tracker`,
  });

export const getMachineName = (props) => {
  const { machine, lower = false, mode = 'first' } = props || {};
  if (!machine) return '';
  const { machine_type, model, number } = machine || {};
  const machineTypeName = machineTypes[machine_type];
  if (mode === 'first') {
    const machineTypeNameFormatted =
      (machineTypeName && lower
        ? machineTypeName.toLowerCase()
        : machineTypeName) || '';
    return [machineTypeNameFormatted, model, number].filter((v) => v).join(' ');
  }
  const machineTypeNameFormatted =
    (machineTypeName && lower
      ? machineTypeName.toLowerCase()
      : machineTypeName) || '';
  return [[model, number].filter((v) => v).join(' '), machineTypeNameFormatted]
    .filter((v) => v)
    .join(', ');
};

const macinesList = (listOfMachines) => {
  const list = {};
  const l = listOfMachines.length;
  for (let i = 0; i < l; i++) {
    const item = listOfMachines[i];
    list[item.id] = item;
  }
  return list;
};

export const fetchMachinesIfNeeded = () => async (dispatch, getState) => {
  const customerId = getAdvisorCustomerId() || getCustomerId();
  const machineList = getState().machines.item[customerId];
  if (!machineList && !getState().machines.isPending) {
    try {
      dispatch(createAction(itemConstants.SET_IS_PENDING)(true));
      const item = await getMachines();
      const previousState = getState().machines.item;
      const result = {
        ...previousState,
        [customerId]: macinesList(item.data),
        [`${customerId}_clear`]: item.data,
      };
      dispatch(createAction(itemConstants.SET_ITEM)(result));
    } finally {
      dispatch(createAction(itemConstants.SET_IS_PENDING)(false));
    }
  }
};

// selectors
export const getMachinesSelector = createSelector(
  [
    (state) => state.machines.item,
    (state) => state.machines.isPending,
    (state) => state.auth.currentCustomer.id,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getMachinesByCustomerIdOriginalSelector = createSelector(
  [
    (state) => state.machines.item,
    (state) => state.machines.isPending,
    (state) => state.auth.currentCustomer.id,
  ],
  (cache, isPending) => {
    const customerId = getAdvisorCustomerId() || getCustomerId();

    return {
      list: cache?.[`${customerId}_clear`] || [],
      isPending,
    };
  }
);

export const getMachinesByCustomerIdSelector = createSelector(
  [
    (state) => state.machines.item,
    (state) => state.machines.isPending,
    (state) => state.auth.currentCustomer.id,
  ],
  (cache, isPending) => {
    const customerId = getAdvisorCustomerId() || getCustomerId();
    return {
      list: cache?.[customerId] || {},
      isPending,
    };
  }
);

export const getMachinesHashSelector = createSelector(
  [(state) => state.machines.item, (state) => state.auth.currentCustomer.id],
  (item) => {
    const customerId = getAdvisorCustomerId() || getCustomerId();
    const list = item[`${customerId}_clear`] || [];
    return list
      .filter((element) => !!element.tracker_serial)
      .reduce((resultObj, element) => {
        // eslint-disable-next-line no-param-reassign
        resultObj[element.tracker_serial] = {
          ...element,
          machineTypeResult: element.machine_type?.toLowerCase() || '',
        };

        return resultObj;
      }, {});
  }
);
