import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PUBLIC_URL } from 'env';
import { getFileUrl } from 'utils';
import classNames from 'classnames';
import SvgImage from './Scouts/SvgImage';

const getTooltip = (productId) => {
  switch (productId) {
    case 'relief':
      return 'Средний уклон';
    case 'contrast_ndvi_masked':
      return 'Метрика однородности, 0 - полностью однородное поле, чем выше значение - тем сильнее вариации.';
    default:
      return 'Средний NDVI';
  }
};

const renderDescription = (description) => {
  if (!description) {
    return <p />;
  }
  if (Array.isArray(description)) {
    return description.map((element) => <div key={element}>{element}</div>);
  }
  return description;
};

const ListItem = ({
  name,
  isGreyDescription,
  nameIcon,
  description,
  photo_path,
  sowing,
  isBorderless,
  svgIconPath,
  isStaticPhotoPath,
  skipEmptyPhoto,
  color,
  handleItemSelect,
  isActive,
  style,
  avgNdvi,
  isAvgNvdiShow,
  productId,
  avgNdviIsPending,
  onHover,
}) => {
  const resultPhotoPath = useMemo(() => {
    if (!photo_path) {
      return isBorderless
        ? `${PUBLIC_URL}/icons/borderless-file-blank-icon.svg`
        : `${PUBLIC_URL}/images/image-placeholder.svg`;
    }
    return isStaticPhotoPath ? photo_path : getFileUrl(photo_path);
  }, [isStaticPhotoPath, photo_path, isBorderless]);

  return (
    <li
      onMouseEnter={() => onHover?.(true)}
      onMouseLeave={() => onHover?.(false)}
      className={classNames('list-item', isActive ? 'list-item--active' : '')}
      onClick={handleItemSelect}
      style={style}
    >
      {svgIconPath ? (
        <div className="list-item__image-container">
          <div className="list-item__image">
            <SvgImage sowing={sowing} svgPath={svgIconPath} />
          </div>
        </div>
      ) : !color && (!skipEmptyPhoto || photo_path !== null) ? (
        <div className="list-item__image-container">
          <div
            style={{
              backgroundImage: `url(${resultPhotoPath})`,
            }}
            className="list-item__image"
          />
        </div>
      ) : null}
      {color ? (
        <div className="color-list-item">
          <div
            className="color-list-item__color-block"
            style={{
              backgroundColor: color,
            }}
          />
        </div>
      ) : null}
      <div className="list-item__content">
        <div className="list-item__title">
          <div className="list-item__name-icon">
            {nameIcon} {name}
          </div>
          {isAvgNvdiShow && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="productId">
                  <span>{getTooltip(productId)}</span>
                </Tooltip>
              }
            >
              <div
                className={classNames({
                  'list-item__ndvi list-item__ndvi-icon':
                    productId !== 'relief' &&
                    productId !== 'contrast_ndvi_masked',
                  'list-item__ndvi_relief':
                    productId === 'relief' ||
                    productId === 'contrast_ndvi_masked',
                })}
              >
                {productId === 'relief' && (
                  <img
                    className="list-item__ndvi_relief-icon"
                    src={`${PUBLIC_URL}/icons/slope.png`}
                    alt="icon"
                  />
                )}
                {productId === 'contrast_ndvi_masked' && (
                  <img
                    className="list-item__ndvi_relief-icon"
                    src={`${PUBLIC_URL}/icons/upDown.svg`}
                    alt="icon"
                  />
                )}
                {avgNdviIsPending ? (
                  '...'
                ) : avgNdvi ? (
                  <span>
                    {avgNdvi}
                    {productId === 'relief' ? '°' : ''}
                  </span>
                ) : (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: '&ndash;',
                    }}
                  />
                )}
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div
          className={classNames(
            'list-item__description',
            isGreyDescription ? 'list-item__description_grey' : ''
          )}
        >
          {renderDescription(description)}
        </div>
      </div>
    </li>
  );
};

export default ListItem;
